/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import FormattedNumberField from './FormattedNumberField';
import CardFooter from './CardFooter';
import { renderOptionForSelect } from '../utils';
import {
  SAFE_TYPE_OPTIONS,
  PRORATA_RIGHT_OPTIONS,
  DISCOUNT_SAFE_TYPES,
  UNCAPPED_SAFE_TYPES,
  PREMONEY_SAFE_TYPES,
} from '../constants';

import { validateValuation } from '../../../dashboards/components/fundraising/utils';

function SafeTerm({
  step,
  handleBack,
  handleSaveAndContinue,
  marketDataAsOf,
  marketDataMedianRoundSeed,
  marketDataMedianValuation,
  blurredValuationsImage,
}) {
  const { register, errors, watch, control } = useFormContext();
  const fieldErrors = errors && errors.safeTerms;
  const watchSafeType = watch('safeTerms[safeType]');
  const isDiscountSafeType = DISCOUNT_SAFE_TYPES.includes(watchSafeType);
  const isUncappedSafeType = UNCAPPED_SAFE_TYPES.includes(watchSafeType);
  const isPreMoneySafe = PREMONEY_SAFE_TYPES.includes(watchSafeType);

  const valuationAndDiscountFieldClasses = classNames('col-12', {
    'col-md-4': isDiscountSafeType,
    'col-md-6': !isUncappedSafeType && !isDiscountSafeType,
  });
  const proRataRightFieldClasses = classNames('col-12', {
    'col-md-4': isDiscountSafeType,
    'col-md-6': !isUncappedSafeType && !isDiscountSafeType,
    'col-md-12': isUncappedSafeType,
  });
  const proRataOptions = isPreMoneySafe
    ? [PRORATA_RIGHT_OPTIONS[1]]
    : PRORATA_RIGHT_OPTIONS;

  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="card card-border border-primary shadow-light-lg">
          <div className="card-header">
            <span>
              <strong> SAFE Terms </strong>
            </span>
          </div>
          <div className="card-body pb-0">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="form-group mb-5">
                  <label htmlFor="valuationCap">
                    <strong>Document & Type</strong>
                  </label>

                  <select
                    type="select"
                    className="custom-select"
                    data-choices
                    name="safeTerms[safeType]"
                    ref={register({ required: 'SAFE type is required' })}
                  >
                    {renderOptionForSelect(SAFE_TYPE_OPTIONS)}
                  </select>
                  {fieldErrors && fieldErrors.safeType && (
                    <small className="text-danger">
                      {fieldErrors.safeType.message}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              {!isUncappedSafeType && (
                <>
                  <div className={valuationAndDiscountFieldClasses}>
                    <div className="form-group mb-5">
                      <label htmlFor="valuationCap">
                        <strong>Valuation Cap</strong>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          $
                        </span>
                        <FormattedNumberField
                          className="form-control"
                          name="safeTerms[valuationCap]"
                          placeholder="20,000,000"
                          control={control}
                          rules={{
                            required: 'Valuation cap is required',
                            validate: {
                              minValue: validateValuation,
                            },
                          }}
                        />
                      </div>
                      {fieldErrors && fieldErrors.valuationCap && (
                        <small className="text-danger">
                          {fieldErrors.valuationCap.message}
                        </small>
                      )}
                    </div>
                  </div>
                  {isDiscountSafeType && (
                    <div className={valuationAndDiscountFieldClasses}>
                      <div className="form-group mb-5">
                        <label htmlFor="valuationCap">
                          <strong>
                            Discount
                            <i
                              className="fe fe-help-circle ml-2"
                              title="This is the percentage that you would like to offer. The documents will use 100 minus this number to determine the discount"
                            />
                          </strong>
                        </label>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            name="safeTerms[discount]"
                            placeholder="20"
                            ref={register({
                              required: 'Discount is required',
                              max: {
                                value: 50,
                                message:
                                  'Discount cannot exceed 50%. This field represents the discount percentage, not to be confused with the discount rate (which is 100 - discount %). Typical discounts are well under 50%.',
                              },
                            })}
                          />
                          <span className="input-group-text" id="basic-addon2">
                            %
                          </span>
                        </div>

                        {fieldErrors && fieldErrors.discount && (
                          <small className="text-danger">
                            {fieldErrors.discount.message}
                          </small>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className={proRataRightFieldClasses}>
                <div className="form-group mb-5">
                  <label htmlFor="includeProRataRights">
                    <strong>
                      {isPreMoneySafe
                        ? 'Pro Rata Rights'
                        : 'Include Pro Rata Side Letter?'}
                    </strong>
                  </label>
                  <select
                    type="select"
                    className="custom-select"
                    data-choices
                    name="safeTerms[includeProRataRights]"
                    ref={register({
                      required: 'Include Pro Rata side letter is required',
                    })}
                  >
                    {renderOptionForSelect(proRataOptions)}
                  </select>
                  {fieldErrors && fieldErrors.name && (
                    <small className="text-danger">
                      {fieldErrors.includeProRataRights.message}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <hr className="border-gray-300 mb-4" />
            <div className="row">
              <div className="col-12 col-md-6">
                <h3> AngelList Market Data </h3>
                <small>
                  As of&nbsp;
                  {marketDataAsOf}, AngelList Venture market data indicates that
                  the&nbsp;
                  <a href="/safe/market">median startup</a>
                  &nbsp;using SAFEs for a&nbsp;
                  <a href="/safe/market">Seed Stage</a>
                  &nbsp;round raised&nbsp;
                  {marketDataMedianRoundSeed}
                  &nbsp;using a Post Money SAFE with a valuation cap of&nbsp;
                  {marketDataMedianValuation}
                  .
                  <br />
                  <br />
                  <a
                    className="btn btn-sm btn btn-block btn-info-soft mb-6 mb-md-0 lift"
                    href="/safe/new/market"
                  >
                    <i className="fe fe-pie-chart mr-3" />
                    View {marketDataAsOf} Market Terms by Industry & Stage
                  </a>
                </small>
              </div>
              <div className="col-12 col-md-6">
                <a href="/valuations" target="_blank">
                  <img
                    width="100%"
                    className="img img-responsive"
                    src={blurredValuationsImage}
                    alt="Valuations Data Preview (Blurred)"
                  />
                </a>
              </div>
            </div>
            <hr className="border-gray-300 mb-0" />
            <CardFooter
              step={step}
              skipBackBtn
              handleBack={handleBack}
              handleSaveAndContinue={handleSaveAndContinue}
              buttonCopy="Save & Continue"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

SafeTerm.propTypes = {
  step: PropTypes.string.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleSaveAndContinue: PropTypes.func.isRequired,
  marketDataAsOf: PropTypes.string.isRequired,
  marketDataMedianRoundSeed: PropTypes.string.isRequired,
  marketDataMedianValuation: PropTypes.string.isRequired,
  blurredValuationsImage: PropTypes.string.isRequired,
};

export default SafeTerm;
