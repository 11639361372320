/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import CardFooter from './CardFooter';
import { renderOptionForSelect, generateOptionsMap } from '../utils';
import { US_STATES, EMAIL_REGEX } from '../constants';

function CompanyInformationForm({ step, handleBack, handleSaveAndContinue }) {
  const { register, errors } = useFormContext();
  const fieldErrors = errors && errors.companyInformation;

  return (
    <div className="row mb-4">
      <div className="col-12 ">
        <div className="card card-border border-primary shadow-light-lg">
          <div className="card-header">
            <span>
              <strong> Company Information </strong>
            </span>
          </div>
          <div className="card-body pb-0">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-5">
                  <label htmlFor="companyName">Company Legal Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="companyInformation[name]"
                    placeholder="MyStartup Inc."
                    ref={register({
                      required: 'Company legal name is required',
                    })}
                  />
                  {fieldErrors && fieldErrors.name && (
                    <small className="text-danger">
                      {fieldErrors.name.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group mb-5">
                  <label htmlFor="stateOfIncorporation">
                    State of Incorporation
                  </label>
                  <select
                    type="select"
                    className="custom-select"
                    data-choices
                    name="companyInformation[stateOfIncorporation]"
                    ref={register({ required: 'State of incorporation' })}
                    required
                  >
                    {renderOptionForSelect(generateOptionsMap(US_STATES))}
                  </select>
                  {fieldErrors && fieldErrors.stateOfIncorporation && (
                    <small className="text-danger">
                      {fieldErrors.stateOfIncorporation.message}
                    </small>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group mb-5">
                  <label htmlFor="founderName">Founder / Signatory Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="companyInformation[founderName]"
                    ref={register({ required: 'Founder name is required' })}
                    placeholder="Joanna Toal"
                  />
                  {fieldErrors && fieldErrors.founderName && (
                    <small className="text-danger">
                      {fieldErrors.founderName.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group mb-5">
                  <label htmlFor="founderName">Signatory Title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="companyInformation[founderTitle]"
                    ref={register({ required: 'Founder title is required' })}
                    defaultValue="CEO"
                    placeholder="CEO"
                  />
                  {fieldErrors && fieldErrors.founderName && (
                    <small className="text-danger">
                      {fieldErrors.founderName.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group mb-5">
                  <label htmlFor="founderEmail">Company Signatory Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="companyInformation[founderEmail]"
                    ref={register({
                      required: 'Founder email is required',
                      pattern: {
                        value: EMAIL_REGEX,
                        message: 'Please enter valid email address',
                      },
                    })}
                    placeholder="joanna@mystartup.com"
                  />
                  {fieldErrors && fieldErrors.founderEmail && (
                    <small className="text-danger">
                      {fieldErrors.founderEmail.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-12">
                <div className="form-group mb-5">
                  <label htmlFor="companyAddress">Company Adddress</label>
                  <textarea
                    type="email"
                    className="form-control"
                    name="companyInformation[companyAddress]"
                    ref={register()}
                    placeholder="100 Main St #102, San Francisco, CA, 94105"
                  />
                  {fieldErrors && fieldErrors.companyAddress && (
                    <small className="text-danger">
                      {fieldErrors.companyAddress.message}
                    </small>
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr className="border-gray-300 mb-0" />
          <CardFooter
            step={step}
            handleBack={handleBack}
            handleSaveAndContinue={handleSaveAndContinue}
          />
        </div>
      </div>
    </div>
  );
}

CompanyInformationForm.propTypes = {
  step: PropTypes.string.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleSaveAndContinue: PropTypes.func.isRequired,
};

export default CompanyInformationForm;
