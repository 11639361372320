import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

const FormattedNumberField = ({
  name,
  control,
  className,
  placeholder,
  rules,
}) => (
  <Controller
    render={({ onChange, value }) => (
      <NumberFormat
        className={className}
        placeholder={placeholder}
        allowNegative={false}
        thousandSeparator
        decimalScale="2"
        onValueChange={(v) => onChange(v.value)}
        value={value}
      />
    )}
    rules={rules}
    name={name}
    control={control}
    fixedDecimalScale
  />
);

FormattedNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

FormattedNumberField.defaultProps = {
  rules: {},
  placeholder: '',
  className: '',
};

export default FormattedNumberField;
