import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

import ESign from '../../../css/icons/ESign';
import DownloadSvg from '../../../css/icons/Download';

function CardFooter({
  step,
  skipBackBtn,
  loading,
  showDownloadPdf,
  showGeneratePdf,
  handleBack,
  handleDownloadPdf,
  handleGatherSignatures,
  handleSaveAndContinue,
  buttonCopy,
}) {
  return (
    <div className="card-footer py-5">
      <div className="row align-items-center">
        <div className="col-12 col-md">
          {!skipBackBtn && (
            <button
              type="button"
              className="btn btn-secondary mb-6 mb-md-0 lift"
              onClick={() => handleBack(step)}
            >
              <i className="fe fe-arrow-left mr-3" />
              Back
            </button>
          )}
        </div>
        <div className="col-12 col-md-auto">
          {!showDownloadPdf && !showGeneratePdf && (
            <button
              type="button"
              className="btn btn-primary mb-6 mb-md-0 lift"
              onClick={() => handleSaveAndContinue(step)}
            >
              {buttonCopy}
              <i className="fe fe-arrow-right ml-3" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

CardFooter.defaultProps = {
  loading: false,
  skipBackBtn: false,
  showDownloadPdf: false,
  showGeneratePdf: false,
  buttonCopy: 'Save & Continue',
  handleDownloadPdf: () => {},
  handleGatherSignatures: () => {},
  handleSaveAndContinue: () => {},
};

CardFooter.propTypes = {
  skipBackBtn: PropTypes.bool,
  showGeneratePdf: PropTypes.bool,
  buttonCopy: PropTypes.string,
  loading: PropTypes.bool,
  showDownloadPdf: PropTypes.bool,
  step: PropTypes.string.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleDownloadPdf: PropTypes.func,
  handleGatherSignatures: PropTypes.func,
  handleSaveAndContinue: PropTypes.func,
};

export default CardFooter;
