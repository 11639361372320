import React from 'react';

export const renderOptionForSelect = (options) =>
  options.map((option) => (
    <option value={option.value} key={option.value}>
      {option.label}
    </option>
  ));

export const generateOptionsMap = (options) =>
  Object.keys(options).map((value) => ({
    label: options[value],
    value: options[value],
  }));

export const downloadPdf = (safePdfBlobData) => {
  const blob = new Blob([safePdfBlobData], { type: 'application/pdf' });

  // IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(
      safePdfBlobData,
      `SAFE-new-${new Date().getTime()}.pdf`,
    );
    return;
  }

  // // Chrome, FF
  const fileUrl = URL.createObjectURL(blob);
  const w = window.open(fileUrl, '_blank');
  // eslint-disable-next-line no-unused-expressions
  w && w.focus();

  const a = document.createElement('a');
  a.href = fileUrl;
  a.download = `SAFE-new-${new Date().getTime()}.pdf`;
  document.body.appendChild(a);
  a.click();
};
