/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

import CardFooter from './CardFooter';
import { downloadPdf } from '../utils';

import ESign from '../../../css/icons/ESign';
import DownloadSvg from '../../../css/icons/Download';

import Modal from '../../../dashboards/components/common/Modal';
import useModal from '../../../dashboards/components/common/hooks/useModal';
import GenerateLinksModal from './GenerateLinksModal';

function GeneratePdf({ step, loading, safePdfBlobData, handleBack }) {
  const { modal, openModal, toggleModal } = useModal();

  const handleDownloadPdf = () => downloadPdf(safePdfBlobData);

  const handleGatherSignatures = () => {
    openModal();
  };

  const showDownloadPdf = !!safePdfBlobData;
  const showGeneratePdf = true;

  return (
    <div className="row mb-4">
      <Modal
        size="lg"
        modal={modal}
        toggleModal={toggleModal}
        headerText="Gather Signatures"
      >
        <GenerateLinksModal toggleModal={toggleModal} />
      </Modal>
      <div className="col-12">
        <div className="card card-border border-primary shadow-light-lg">
          <div className="card-header">
            <span>
              <strong> Generate SAFE </strong>
            </span>
          </div>
          <div className="card-body pb-0">
            <div className="row">
              <div className="col-12 col-md-8">
                <h4> eSign with SAFE.new </h4>
                <ul>
                  <li>
                    {' '}
                    SAFE.new can collect eSignatures from founders or investors{' '}
                  </li>
                  <li> Share a single link with the investor </li>
                  <li> Totally Free </li>
                </ul>
              </div>
              <div className="col-12 col-md-4">
                <button
                  type="button"
                  className="btn btn-primary btn-block mb-6 mb-md-0 lift"
                  onClick={handleGatherSignatures}
                >
                  <ESign className="mr-2" />
                  Sign Now
                </button>

                {!showDownloadPdf && showGeneratePdf && (
                  <button
                    type="submit"
                    className="btn btn-secondary btn-block btn-xs mb-6 mb-md-0 mt-4"
                  >
                    {loading ? (
                      <Spinner color="primary" size="sm" className="mr-2" />
                    ) : (
                      <DownloadSvg className="mr-2" />
                    )}
                    <span>View PDF</span>
                  </button>
                )}
                {showDownloadPdf && (
                  <button
                    type="button"
                    className="btn btn-secondary btn-block btn-xs mb-6 mb-md-0 mt-4"
                    onClick={handleDownloadPdf}
                  >
                    <DownloadSvg className="mr-2" />
                    View PDF
                  </button>
                )}
              </div>
            </div>

            <hr className="border-gray-300 mb-2" />

            <p>
              By using this service, I agree to the&nbsp;
              <a href="/terms" target="_blank">
                Privacy Policy
              </a>
              ,&nbsp;
              <a href="/terms" target="_blank">
                Terms of Service
              </a>
              , and the disclosures listed below.
            </p>

            <span className="mt-4">
              <ul>
                <li className="mt-1">
                  <small>
                    You must consult with your own attorney or legal counsel for
                    any legal advice. AngelList is not a law firm and its
                    employees cannot act as your attorney. This is not a
                    substitute for an attorney.
                  </small>
                </li>
                <li className="mt-1">
                  <small>
                    You are responsible for reviewing the accuracy of the data
                    you enter and the documents being generated. Other than the
                    data you provide, these documents will not be customized for
                    you.
                  </small>
                </li>
              </ul>
            </span>
          </div>
          <hr className="border-gray-300 mb-0" />
          <CardFooter
            step={step}
            loading={loading}
            showGeneratePdf
            showDownloadPdf={!!safePdfBlobData}
            handleBack={handleBack}
            handleDownloadPdf={handleDownloadPdf}
            handleGatherSignatures={handleGatherSignatures}
          />
        </div>
      </div>
    </div>
  );
}

GeneratePdf.defaultProps = {
  safePdfBlobData: {},
};

GeneratePdf.propTypes = {
  step: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  safePdfBlobData: PropTypes.object,
  handleBack: PropTypes.func.isRequired,
};

export default GeneratePdf;
