import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';

import SafeTermForm from './components/SafeTermForm';
import CompanyInformationForm from './components/CompanyInformationForm';
import InvestorInformationForm from './components/InvestorInformationForm';
import GeneratePdf from './components/GeneratePdf';
import { downloadPdf } from './utils';

import { SAFE_NAV_MENU_ITEMS, NAVIGATION_STEPS } from './constants';

function SafeGenerator(props) {
  const { marketDataMedianValuationFloat } = props;

  const defaultValues = {
    safeTerms: {
      safeType: 'post_money_val_cap',
      valuationCap: marketDataMedianValuationFloat || 12000000,
      includeProRataRights: 'no_prorata',
    },
    investorInformation: {
      entityType: 'individual',
    },
  };
  const safeFormState = JSON.parse(localStorage.getItem('safeFormState'));
  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      ...safeFormState,
    },
  });
  const [currentStep, setCurrentStep] = useState('safeTerms');
  const [safePdfBlobData, setSafePdfBlobData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    methods.reset({ ...defaultValues, ...safeFormState });
  }, [currentStep]);

  const handleSaveAndContinue = (step) => {
    methods.trigger().then((isValid) => {
      if (isValid) {
        const newFormState = {
          ...safeFormState,
          [step]: methods.getValues()[step],
        };

        localStorage.setItem('safeFormState', JSON.stringify(newFormState));
        setCurrentStep(NAVIGATION_STEPS[step].next);
        setSafePdfBlobData(null);
      }
    });
  };

  const handleBack = (step) => {
    setCurrentStep(NAVIGATION_STEPS[step].prev);
  };

  const onSubmit = () => {
    setLoading(true);

    localStorage.setItem('safeFormState', JSON.stringify(safeFormState));
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');

    fetch('/safe/create', {
      method: 'POST',
      credentials: 'same-origin',
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify({ safe_gen: safeFormState }),
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
      })
      .then((data) => {
        setSafePdfBlobData(data);
        setLoading(false);
        downloadPdf(data);
      });
  };

  const renderNavMenuItems = () =>
    Object.keys(SAFE_NAV_MENU_ITEMS).map((menuItem, index) => {
      const stepIconClasses = classNames('step-icon step-icon-xs', {
        'active step-icon-primary': currentStep === menuItem,
        'step-icon-soft-primary': currentStep !== menuItem,
      });

      return (
        <li className="step-item" key={index}>
          <div className="step-content-wrapper">
            <span className={stepIconClasses}>{index + 1}</span>
            <div className="step-content">
              <span className="step-inline-title">
                {SAFE_NAV_MENU_ITEMS[menuItem]}
              </span>
            </div>
          </div>
        </li>
      );
    });

  return (
    <>
      <div className="row mb-md-6 mb-xs-2 d-none d-lg-flex">
        <div className="col-1">&nbsp;</div>
        <nav className="step step-lg step-inline col-11 col-lg-11">
          {renderNavMenuItems()}
        </nav>
      </div>

      <div className="safeGeneratorContainer">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {currentStep === 'safeTerms' && (
              <SafeTermForm
                step="safeTerms"
                handleBack={handleBack}
                handleSaveAndContinue={handleSaveAndContinue}
                {...props}
              />
            )}
            {currentStep === 'companyInformation' && (
              <CompanyInformationForm
                step="companyInformation"
                handleBack={handleBack}
                handleSaveAndContinue={handleSaveAndContinue}
              />
            )}
            {currentStep === 'investorInformation' && (
              <InvestorInformationForm
                step="investorInformation"
                handleBack={handleBack}
                handleSaveAndContinue={handleSaveAndContinue}
              />
            )}
            {currentStep === 'generatePdf' && (
              <GeneratePdf
                step="generatePdf"
                handleBack={handleBack}
                loading={loading}
                safePdfBlobData={safePdfBlobData}
              />
            )}
          </form>
        </FormProvider>
      </div>
    </>
  );
}

SafeGenerator.propTypes = {
  marketDataMedianValuationFloat: PropTypes.isRequired,
};

export default SafeGenerator;
