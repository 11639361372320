/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Spinner } from 'reactstrap';
import useCopyClipboard from '../../../dashboards/components/common/hooks/useCopyClipboard';

const GenerateLinksModal = () => {
  const [linksLoading, setLinksLoading] = useState(false);
  const [isCopied, setCopied] = useCopyClipboard();

  const [links, setLinks] = useState({
    investorLink: null,
    founderLink: null,
  });

  const safeFormState = JSON.parse(localStorage.getItem('safeFormState'));

  const onGenerateLinksSubmit = () => {
    if (linksLoading) return;

    setLinksLoading(true);
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');

    fetch('/safe/generate_links', {
      method: 'POST',
      credentials: 'same-origin',
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify({ safe_gen: safeFormState }),
    })
      .then((response) => response.json())
      .then((response) => {
        setLinks({
          investorLink: response.investor_link,
          founderLink: response.founder_link,
        });
        setLinksLoading(false);
      });
  };

  if (links.investorLink === null && !linksLoading) {
    onGenerateLinksSubmit();
  }
  const founderLinkText = links.founderLink || 'Loading...';
  const investorLinkText = links.investorLink || 'Loading...';

  const investorCopyButtonText = isCopied ? (
    <>
      <i className="fe fe-check" />
      Copied
    </>
  ) : (
    'Copy Link'
  );

  return (
    <>
      <div className="mb-4">
        <div className="row">
          <div className="col-12 col-md-6">
            <h3 className="text-center">Founder</h3>
            <p className="text-center">
              If you are the signatory for the SAFE, sign as the founder by
              using this URL
            </p>

            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                disabled
                value={founderLinkText}
                aria-label="Founder Link URL"
                aria-describedby="founder-link-1"
              />
            </div>
            <a
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              href={links.founderLink}
              disabled={linksLoading}
              className="btn btn-block btn-info-soft"
            >
              {linksLoading ? (
                <>
                  <Spinner color="primary" size="sm" className="mr-2" />
                  <span> Loading </span>
                </>
              ) : (
                'Sign Now'
              )}
            </a>
          </div>
          <div className="col-12 col-md-6">
            <h3 className="text-center">Investor</h3>
            <p className="text-center">
              Send this link to the investor signing the SAFE
            </p>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                disabled
                value={investorLinkText}
                aria-label="Investor Link URL"
                aria-describedby="investor-link-1"
              />
            </div>
            <div
              onClick={() => setCopied(links.investorLink)}
              disabled={linksLoading}
              className="btn btn-block btn-success-soft"
            >
              {linksLoading ? (
                <>
                  <Spinner color="primary" size="sm" className="mr-2" />
                  <span> Loading </span>
                </>
              ) : (
                investorCopyButtonText
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateLinksModal;
