/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import FormattedNumberField from './FormattedNumberField';
import CardFooter from './CardFooter';
import { EMAIL_REGEX, INVESTOR_TYPE_OPTIONS } from '../constants';
import { renderOptionForSelect } from '../utils';

function InvestorInformationForm({ step, handleBack, handleSaveAndContinue }) {
  const { register, errors, watch, control } = useFormContext();
  const fieldErrors = errors && errors.investorInformation;
  const watchEntityType = watch('investorInformation[entityType]');

  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="card card-border border-primary shadow-light-lg">
          <div className="card-header">
            <span>
              <strong> Investor Information </strong>
            </span>
          </div>
          <div className="card-body pb-0">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group mb-5">
                  <label htmlFor="amount">Investment Amount</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      $
                    </span>
                    <FormattedNumberField
                      className="form-control"
                      name="investorInformation[amount]"
                      placeholder="10,000,000"
                      control={control}
                      rules={{
                        required: 'Investment amount is required',
                      }}
                    />
                  </div>
                  {fieldErrors && fieldErrors.amount && (
                    <small className="text-danger">
                      {fieldErrors.amount.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="form-group mb-5">
                  <label htmlFor="companyName">Investment Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="investorInformation[investmentDate]"
                    ref={register()}
                    defaultValue={new Date().toISOString().slice(0, 10)}
                  />
                  {fieldErrors && fieldErrors.companyName && (
                    <small className="text-danger">
                      {fieldErrors.companyName.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="form-group mb-5">
                  <label htmlFor="companyName">
                    Investor
                    <small>&nbsp;(Legal Entity Name)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={
                      watchEntityType === 'individual'
                        ? 'Samantha Smith'
                        : 'Amazing Ventures Fund II L.P.'
                    }
                    name="investorInformation[companyName]"
                    ref={register}
                  />
                  {fieldErrors && fieldErrors.companyName && (
                    <small className="text-danger">
                      {fieldErrors.companyName.message}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-4">
                <span>
                  <strong> Investor Signature Block </strong>
                </span>
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-5">
                  <label htmlFor="companyName">Entity Type</label>
                  <select
                    type="select"
                    className="custom-select"
                    data-choices
                    name="investorInformation[entityType]"
                    ref={register({ required: 'Entity type is required' })}
                  >
                    {renderOptionForSelect(INVESTOR_TYPE_OPTIONS)}
                  </select>
                  {fieldErrors && fieldErrors.entityType && (
                    <small className="text-danger">
                      {fieldErrors.entityType.message}
                    </small>
                  )}
                </div>
              </div>

              {(watchEntityType === 'venture_fund' ||
                watchEntityType === 'entity') && (
                <>
                  <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                      <label htmlFor="signatory">
                        Name of authorized signatory
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="investorInformation[signatory]"
                        ref={register()}
                        placeholder="Samantha Smith"
                      />
                      {fieldErrors && fieldErrors.signatory && (
                        <small className="text-danger">
                          {fieldErrors.signatory.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                      <label htmlFor="signatoryTitle">
                        Signatory Title&nbsp;
                        <small>(optional)</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="investorInformation[signatoryTitle]"
                        ref={register()}
                        placeholder="Authorized Person"
                      />
                      {fieldErrors && fieldErrors.signatoryTitle && (
                        <small className="text-danger">
                          {fieldErrors.signatoryTitle.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                      <label htmlFor="investorEmail">Investor Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="investorInformation[investorEmail]"
                        ref={register({
                          required: 'Investor email is required',
                          pattern: {
                            value: EMAIL_REGEX,
                            message: 'Please enter valid email address',
                          },
                        })}
                        placeholder="portfolio@venturefund.com"
                      />
                      {fieldErrors && fieldErrors.investorEmail && (
                        <small className="text-danger">
                          {fieldErrors.investorEmail.message}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                      <label htmlFor="investorAddress">
                        Investor Address&nbsp;
                        <small>(optional)</small>
                      </label>
                      <textarea
                        type="email"
                        className="form-control"
                        name="investorInformation[investorAddress]"
                        ref={register()}
                        placeholder="100 Main St, PO Box 2233 San Francisco, CA"
                      />
                      {fieldErrors && fieldErrors.investorAddress && (
                        <small className="text-danger">
                          {fieldErrors.investorAddress.message}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                      <label htmlFor="investorByLines">
                        Additional By Lines&nbsp;
                        <small>(optional)</small>
                      </label>
                      <textarea
                        type="email"
                        className="form-control"
                        name="investorInformation[investorByLines]"
                        ref={register()}
                        placeholder="By Amazing Venture GP, LLC, its General Partner"
                      />
                      {fieldErrors && fieldErrors.investorByLines && (
                        <small className="text-danger">
                          {fieldErrors.investorByLines.message}
                        </small>
                      )}
                    </div>
                  </div>
                </>
              )}
              {watchEntityType === 'individual' && (
                <>
                  <div className="col-12 col-md-6">
                    <div className="form-group mb-5">
                      <label htmlFor="investorEmail">Investor Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="investorInformation[investorEmail]"
                        ref={register({
                          required: 'Investor email is required',
                          pattern: {
                            value: EMAIL_REGEX,
                            message: 'Please enter valid email address',
                          },
                        })}
                        placeholder="samantha@mystartup.com"
                      />
                      {fieldErrors && fieldErrors.investorEmail && (
                        <small className="text-danger">
                          {fieldErrors.investorEmail.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="form-group mb-5">
                      <label htmlFor="investorAddress">
                        Investor Adddress&nbsp;
                        <small>(optional)</small>
                      </label>
                      <textarea
                        type="email"
                        className="form-control"
                        name="investorInformation[investorAddress]"
                        ref={register()}
                        placeholder="100 Main St #102, San Francisco, CA, 94105"
                      />
                      {fieldErrors && fieldErrors.investorAddress && (
                        <small className="text-danger">
                          {fieldErrors.investorAddress.message}
                        </small>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <hr className="border-gray-300 mb-0" />
          <CardFooter
            step={step}
            handleBack={handleBack}
            handleSaveAndContinue={handleSaveAndContinue}
          />
        </div>
      </div>
    </div>
  );
}

InvestorInformationForm.propTypes = {
  step: PropTypes.string.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleSaveAndContinue: PropTypes.func.isRequired,
};

export default InvestorInformationForm;
